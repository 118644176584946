@import '../variables';

#shapespark {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: white;
}

/*--------------Style header start--------------*/

.header {
  width: 100%;
  //height: 60px;
  display: flex;

  .tabs {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid $main-color;

    .border-right {
      border-right: 1px solid $main-color;
    }

    button {
      float: left;
      outline: none;
      cursor: pointer;
      transition: 0.3s;
      font-size: 1rem;
      height: 100%;
      width: 33.33%;
      border: 0;
    }
  }
  .tab-link:after {
    content: '▲';
    color: #444;
    font-weight: bold;
    margin-left: 5px;
  }
  .tab-link-active {
    background: $main-color;
    color: white;
  }
  .tab-link-active:after {
    color: white;
    content: "▼";
  }
}

/*--------------Style header end--------------*/

/*--------------Style main start--------------*/
.main {
  display: flex;
  height: 100vh;

  .render {
    display: flex;
    iframe {
      width: 100%;
      border: none;
    }
  }
  .show-sidebar{
    position: fixed;
    right: 0;
    width: 25px;
    height: 25px;
    background: white;
    cursor: pointer;
    svg {
      font-size: 24px;
      color: $main-color;
    }
  }
  .close-sidebar {
    width: 32px;
    height: 32px;
    top: 50%;
    position: fixed;
    background-color: white;
    cursor: pointer;
    right: 280px;
    border-radius: 5px 0 0 5px;

    svg {
      font-size: 32px;
      color: $main-color;
    }
  }
  .sidebar {
    max-width: 280px;
    width: 100%;

    .list-choose-colors {
      width: 100%;
      max-width: 280px;
      overflow-y: scroll;
      height: 100vh;
      //border-radius: 5px 0 0 5px;
      img {
        width: 100%;
        height: 80px;
      }
      .title {
        margin: 15px 0 6px 0;
      }
    }
    .choose-colors {
      display: inline-block;
      margin: 8px;
    }
    .choose-color {
      float: left;
      width: 50%;
      padding: 5px;
      border: solid 1px #ffffff;
      cursor: pointer;

      span {
        font-size: 13px;
        text-align: center;
        display: grid;
      }
    }

    .MuiAccordion-root {
      margin: 0;

      .ac-parent {
        background-color: $main-color;
        color: white;
        .MuiSvgIcon-root {
          color: white;
        }
        border-bottom: 1px solid white;

        .ac-parent-title {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
        }
      }
      .MuiAccordionSummary-root {
        padding: 0 8px;
        min-height: 45px;
        text-align: center;
        width: 100%;

        &.Mui-expanded {
          margin: 0;
          min-height: 45px
        }

        .MuiAccordionSummary-content {
          margin: 10px 8px;
        }

      }
      .MuiAccordionDetails-root {
        padding: 0;
        //min-height: 45px;
        width: 100%;
        text-align: center;
      }
    }
    .selected-material {
      font-size: 10px;
      position: absolute;
      bottom:2px
    }

    .ac-not-exist .ac-parent {
      background-color: $not-exist-color;
      color: #9e9e9e !important;

      .MuiSvgIcon-root {
        color: #9e9e9e !important;
      }
    }
  }
  .accordion-custom-root {
    .MuiAccordionDetails-root {
      padding: 10px !important;
    }
  }
}

/*--------------Style main end--------------*/

/*--------------Style extensions start--------------*/
.extensions {
  max-height: 340px;
  overflow-y: auto;
  width: 100%;
  p {
    max-height: 340px;
    color: black;
    span {
      display: flex;
      padding: 5px 0;
    }
  }

  button {
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;

    .active {
      border: solid 2px $main-color;
    }
  }
}

/*--------------Style extensions end--------------*/

.active {
  border: solid 1px $main-color;
  border-radius: 2px;
}

.btn-popup {
  width: 100%;
  background-color: white;
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px 12px;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  height: 45px;
}

.btn-popup-active {
  //background: $main-color;
  //color: white;
  color: black;
}

.btn-action {
  width: 100%;
  height: 45px;
  display: inline-flex;

  .btn {
    background-color: $main-color;
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    height: 100%;
  }

  .btn-save, .btn-export {
    width: 50%;

  }

  .btn-distance {
    width: 50%;
    margin: 0 auto;
  }
  .distance-active {
    margin: 0 20px;
    background-color: #2b7ac1 !important;
    //background-color: #5B9BD5 !important;
  }
  .distance-no-active {
    margin: 0 20px;
    background-color: #FF0000 !important;
  }
}

@media only screen and (max-width: 500px) {
  .main {
    display: contents;
    .close-sidebar {
      display: none !important;
    }
    .render {
      height: calc((100vh / 3) * 2) !important;
      width: 100% !important;
    }
    .sidebar {
      max-width: 100%;
      .list-choose-colors {
        max-width: 100%;
        overflow-y: scroll;
        height: calc(100vh / 3) !important;
      }
    }
  }
}


.cg-btn-active{
  background-color: #414bb2 !important;
  border-bottom: 1px solid white !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cg-btn-active p{
  color: #ffffff;
  width: 100%;
}