@import './variables';

label {
  &.Mui-focused {
    color: $main-color;
    border-color: $main-color;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: $main-color
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $main-color
}

.MuiInput-root {
  input {
    font-size: 14px;
  }

  &:after {
    border-bottom: 1px solid $main-color;
  }

  &:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid $main-color;
  }
}