@import './variables';

.properties-table-scroll {
  display: block;
  width: 100%;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}

.user {
  width: 100%;
  height: calc(100vh - 60px);
  padding-bottom: 15px;
  overflow-x: auto;
}
.user-action {
  margin: 0 auto;
  max-width: 350px;
  padding-top: 4rem;
  text-align: center;

  .parent {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);

    .header {
      padding: 1rem;
      background: $main-color;
      color: white;
    }
    .body {
      padding: 2rem;
    }
    .footer span {
      cursor: pointer;
    }
    svg {
      padding-right: 8px;
    }
  }
}

#schedule_dialog {
  table {
    font-size: 12px;

    thead {
      th {
        border: 1px solid #00000070;
        border-bottom: 0;
        vertical-align: middle;
      }

      .div-line {
        position: relative;
        height: 100%;
        width: 100%;

        .month {
          position: absolute;
          width: 100%;
          text-align: right;
          padding: 12px;
        }
        .process {
          position: absolute;
          width: 100%;
          bottom: 0;
          text-align: left;
          padding: 12px;
        }
      }
    }

    tbody {
      td {
        border: 1px solid #00000070;
        vertical-align: middle;
        min-width: 42px;
        padding: 10px 4px;
      }
      .agreed {
        width: 21px;
        height: 21px;
        border: 1px solid red;
        border-radius: 50%;
        margin-top: -21px;
        margin-left: calc(100% - 11px);
        font-size: 14px;
        display: flow-root;
        font-weight: bold;
        position: relative;

        span {
          color: red;
        }
      }
    }
  }
}