@import './variables';

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-text-decoration-skip: objects
}

img {
  vertical-align: middle;
  border-style: none
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

.h1, h1 {
  font-size: 2.5rem
}

.h2, h2 {
  font-size: 2rem
}

.h3, h3 {
  font-size: 1.75rem
}

.h4, h4 {
  font-size: 1.5rem
}

.h5, h5 {
  font-size: 1.25rem
}

.h6, h6 {
  font-size: 1rem
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.bg-primary {
  background-color: #007bff !important
}

.bg-secondary {
  background-color: #6c757d !important
}

.bg-success {
  background-color: #28a745 !important
}

.bg-info {
  background-color: #17a2b8 !important
}

.bg-warning {
  background-color: #ffc107 !important
}

.bg-danger {
  background-color: #dc3545 !important
}

.bg-light {
  background-color: #f8f9fa !important
}

.bg-dark {
  background-color: #343a40 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border {
  border: 1px solid #dee2e6 !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #007bff !important
}

.border-secondary {
  border-color: #6c757d !important
}

.border-success {
  border-color: #28a745 !important
}

.border-info {
  border-color: #17a2b8 !important
}

.border-warning {
  border-color: #ffc107 !important
}

.border-danger {
  border-color: #dc3545 !important
}

.border-light {
  border-color: #f8f9fa !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

.d-contents {
  display: contents !important
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.m-0 {
  margin: 0 !important
}

.mt-0, .my-0 {
  margin-top: 0 !important
}

.mr-0, .mx-0 {
  margin-right: 0 !important
}

.mb-0, .my-0 {
  margin-bottom: 0 !important
}

.ml-0, .mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1, .my-1 {
  margin-top: .25rem !important
}

.mr-1, .mx-1 {
  margin-right: .25rem !important
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important
}

.ml-1, .mx-1 {
  margin-left: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.mt-2, .my-2 {
  margin-top: .5rem !important
}

.mr-2, .mx-2 {
  margin-right: .5rem !important
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important
}

.ml-2, .mx-2 {
  margin-left: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.mt-3, .my-3 {
  margin-top: 1rem !important
}

.mr-3, .mx-3 {
  margin-right: 1rem !important
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important
}

.ml-3, .mx-3 {
  margin-left: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-5, .my-5 {
  margin-top: 3rem !important
}

.mr-5, .mx-5 {
  margin-right: 3rem !important
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important
}

.ml-5, .mx-5 {
  margin-left: 3rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0, .py-0 {
  padding-top: 0 !important
}

.pr-0, .px-0 {
  padding-right: 0 !important
}

.pb-0, .py-0 {
  padding-bottom: 0 !important
}

.pl-0, .px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.pt-1, .py-1 {
  padding-top: .25rem !important
}

.pr-1, .px-1 {
  padding-right: .25rem !important
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important
}

.pl-1, .px-1 {
  padding-left: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.pt-2, .py-2 {
  padding-top: .5rem !important
}

.pr-2, .px-2 {
  padding-right: .5rem !important
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important
}

.pl-2, .px-2 {
  padding-left: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.pt-3, .py-3 {
  padding-top: 1rem !important
}

.pr-3, .px-3 {
  padding-right: 1rem !important
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important
}

.pl-3, .px-3 {
  padding-left: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.pt-5, .py-5 {
  padding-top: 3rem !important
}

.pr-5, .px-5 {
  padding-right: 3rem !important
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important
}

.pl-5, .px-5 {
  padding-left: 3rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto, .my-auto {
  margin-top: auto !important
}

.mr-auto, .mx-auto {
  margin-right: auto !important
}

.mb-auto, .my-auto {
  margin-bottom: auto !important
}

.ml-auto, .mx-auto {
  margin-left: auto !important
}

.text-justify {
  text-align: justify !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

.text-primary {
  color: #007bff !important
}

.text-secondary {
  color: #6c757d !important
}

.text-success {
  color: #28a745 !important
}

.text-info {
  color: #17a2b8 !important
}

.text-warning {
  color: #ffc107 !important
}

.text-danger {
  color: #dc3545 !important
}

.text-light {
  color: #f8f9fa !important
}

.text-dark {
  color: #343a40 !important
}

.text-muted {
  color: #6c757d !important
}

.text-main {
  color: $main-color;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs-14px {
  font-size: 14px;
}